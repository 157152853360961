.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  max-width: 300px;
}

.notification {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  animation: slideIn 0.3s ease-out;
}

.notification:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.notification-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.notification-sender {
  font-weight: bold;
  color: #333;
}

.notification-time {
  font-size: 0.8em;
  color: #666;
}

.notification-content {
  color: #444;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
} 