.delete-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.tab {
  padding: 0.5rem 2rem;
  border: none;
  border-radius: var(--radius);
  background: var(--background-light);
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab.active {
  background: rgb(var(--isticanje));
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: var(--radius);
  max-width: 500px;
  width: 90%;
}

.warning {
  color: #dc3545;
  margin: 1rem 0;
  font-weight: bold;
}

.modal-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

.btn-cancel {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: var(--radius);
  background: var(--background-light);
  cursor: pointer;
}

.btn-delete {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: var(--radius);
  background: #dc3545;
  color: white;
  cursor: pointer;
} 